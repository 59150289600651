import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Link from '../../components/common/link'
import PageContainer from '../../components/common/page-container'
import ReactLogo from '../../assets/logos/reacticon.svg'

export default (props) => (
    <Layout title="React Development">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
              <ReactLogo style={{ fill: '#fff', height: '5rem', marginBottom: '1.5rem' }} />
              
              <Title>React Development</Title>
            </TitleContainer>
        </BackgroundContainer>
        <PageContainer>
          {/* packagedby have extensive experience in building and supporting React applications.
          Previously, we have worked on complex React applications using a popular state management library - Redux. */}

          {/* We have experience of supporting and building complex React apps  */}

          <p>
            packagedby has extensive experience in building robust <Link href="/services/front-end-development">front end applications</Link> using React, with Redux for state management.
          </p>

          <p>
            Previously, we have developed solutions for clients using a server-side framework such as Laravel. 
            We have also built React single page applications with Next.js.
          </p>

          <p>
            We can work with a UX/UI designer of your choice to build custom components for your project. 
            We develop components in isolation using tools such as <a href="https://storybook.js.org/">Storybook</a>.
          </p>

          {/* <ul>
            <li>Next.js</li>
            <li>create-react-app</li>
            <li>SPAs</li>
            <li>Serverless Gatsby apps</li>
            <li>Component systems using Storybook</li>
            <li>styled-components</li>
          </ul> */}
        </PageContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "front-end-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
